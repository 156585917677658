/* ico moon fonts start*/
@font-face {
  font-family: "Icomoon";
  src: url(../src/Assets/fonts/icomoon.eot);
  src: url("../src/Assets/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url(../src/Assets/fonts/icomoon.woff) format("woff"), url(../src/Assets/fonts/icomoon.ttf) format("truetype"), url("../src/Assets/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: 400;
  font-style: normal
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.icon-a:before {
  content: "\e900"
}

.icon-balaclava:before {
  content: "\e901"
}

.icon-bear:before {
  content: "\e902"
}

.icon-block:before {
  content: "\e903"
}

.icon-brute:before {
  content: "\e904"
}

.icon-code:before {
  content: "\e905"
}

.icon-confluence:before {
  content: "\e906"
}

.icon-control:before {
  content: "\e907"
}

.icon-discord:before {
  content: "\e908"
}

.icon-dollar:before {
  content: "\e909"
}

.icon-file:before {
  content: "\e90a"
}

.icon-global:before {
  content: "\e90b"
}

.icon-group:before {
  content: "\e90c"
}

.icon-grow:before {
  content: "\e90d"
}

.icon-key:before {
  content: "\e90e"
}

.icon-mountain:before {
  content: "\e90f"
}

.icon-pegasus:before {
  content: "\e910"
}

.icon-phone:before {
  content: "\e911"
}

.icon-share:before {
  content: "\e912"
}

.icon-shield:before {
  content: "\e913"
}

.icon-stroke:before {
  content: "\e914"
}

.icon-tiktok:before {
  content: "\e915"
}

/* ico moon fonts end */



:root {
  --primary-color: #FFFFFF;
  --secondary-color: #121923;
  --text-color: #C3C4C3;
  --accent-color: #F4A641;
  --border-color: rgba(255, 255, 255, 0.12);
  --background-color: #0f101e;
  --error-color: rgb(230, 87, 87);
  --red-color: #FF0000;
  --green-color: #008000;
  --tg-primary-color: #37b9fc;
  --tg-secondary-color: #37b9fc;
  --default-font: 'Montserrat', sans-serif;
  ;
}

body {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--default-font);
  color: var(--primary-color);
  line-height: 1.6em;
  background: var(--secondary-color) url("./Assets/img/bg-img.svg") top 200px center;
  background-repeat: repeat-y;
  background-size: 100% auto;
}

p {
  line-height: 1.6em;
  margin-bottom: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--default-font);
  margin-top: 0;
  font-weight: 900;
  letter-spacing: -0.02em;
  color: var(--primary-color);
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  transition: all .3s ease;
}



/* navigatoin start */
.navbar-nav .nav-item .nav-link {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 16px;
  position: relative;
}

.navbar-nav .nav-item .nav-link.active {
  color: var(--tg-primary-color);
}

.navbar-nav .nav-item .nav-link::before {
  position: absolute;
  content: "";
  width: 70%;
  height: 2px;
  left: 9px;
  bottom: 5px;
  background-color: var(--tg-primary-color);
  opacity: 0;
  transition: all .3s ease;
}

.navbar-nav .nav-item .nav-link.active::before {
  opacity: 1;
}

.navbar-nav .nav-item .nav-link:hover {
  color: var(--tg-primary-color);
}

.navbar-nav .nav-item .nav-link:hover::before {
  opacity: 1;
}

.navbar-toggler {
  background-color: var(--tg-primary-color);
  color: var(--primary-color);
}

.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: var(--background-color);
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

/* navigatoin end */

/* Banner section start */
.banner-secetion {
  padding: 50px 0;
  position: relative;
}

.btn-primary {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  background-color: var(--tg-primary-color);
  --path: 0px 0px, calc(100% - 20px) 0, 130% 100%, calc(100% - 20px) 100%, 14px 100%, 0px calc(100% - 14px);
  -webkit-clip-path: polygon(var(--path));
  clip-path: polygon(var(--path));
  padding: 10px 15px;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all .3s ease;
}

.btn-primary.style1 {
  background: #798da3;
  color: #fff;
}

.btn-primary:hover {
  background: #798da3;
  color: var(--primary-color);
}

.btn-primary.style1:hover {
  color: var(--primary-color);
  background-color: var(--tg-primary-color);
}

.cust-card {
  background: rgb(39, 49, 186);
  background: linear-gradient(150deg, rgba(39, 49, 186, 1) 20%, rgba(58, 176, 244, 1) 47%, rgba(37, 86, 254, 1) 70%);
  border-radius: 45px;
  padding: 25px;
  border: none;

}

.tab-btn,
.tab-btn2 {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  background-color: var(--tg-secondary-color);
  --path: 0px 0px, calc(100% - 20px) 0, 130% 100%, calc(100% - 20px) 100%, 14px 100%, 0px calc(100% - 14px);
  -webkit-clip-path: polygon(var(--path));
  clip-path: polygon(var(--path));
  padding: 10px 15px;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all .3s ease;
}

.tab-btn:hover {
  background-color: var(--tg-secondary-color);
  color: var(--primary-color);
}

.tab-btn2 {
  background-color: #3c3d4c;
}

.tab-btn2:hover {
  background-color: var(--tg-secondary-color);
  color: var(--primary-color);
}

.btn-cust {
  background-color: var(--tg-secondary-color);
  color: var(--primary-color);
}

.copy-input .form-control {
  border-color: #006fe0;
}

.card-title {
  margin: 0px 0px 11.2px;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--primary-color);
  font-weight: 500;
  text-align: center;
}

.card-subtitle {
  margin: 0px 0px 11.2px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  font-weight: 600;
  color: var(--primary-color);
}

.next-price {

  border: 1px solid #fff;
  --path: 0px 0px, calc(100% - 20px) 0, 130% 100%, calc(100% - 20px) 100%, 14px 100%, 0px calc(100% - 14px);
  -webkit-clip-path: polygon(var(--path));
  clip-path: polygon(var(--path));
  text-align: center;
}

.progress-bar {
  background-color: var(--tg-secondary-color);
}

.amount-raised {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  font-weight: 600;
  color: var(--primary-color);
}

.card-divider {
  margin: 16px 0px;
  flex-shrink: 0;
  display: flex;
  white-space: nowrap;
  text-align: center;
  color: rgb(0, 0, 0);
}

.card-divider::after,
.card-divider::before {
  content: "";
  align-self: center;
  width: 100%;
  border-top: thin solid rgba(254, 254, 254, 0.12);
}

.card-divider-wrapper {
  display: inline-block;
  padding-left: calc(9.6px);
  padding-right: calc(9.6px);
  color: var(--primary-color);
}

.btn-group {
  gap: 16px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.tab-btn span,
.tab-btn2 span {
  margin: 0px 0px 0px 16px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.cust-form .form-label {
  color: var(--primary-color);
}

.claim-btn {
  font-size: 14px;
  color: var(--primary-color);
  white-space: pre-wrap;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.banner-scroll-down {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
}

.banner-scroll-down a {
  color: var(--primary-color);
  display: block;
  width: 30px;
  height: 50px;
  text-align: center;
}

.banner-scroll-down a span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  transform: rotate(45deg);
  margin: -10px 0 0 8px;
  animation: animate 2s infinite;
}

.banner-scroll-down a span:nth-child(2) {
  animation-delay: -0.2s;
}

.banner-scroll-down a span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}

@media (max-width: 1500px) {
  /* .banner-scroll-down {
    bottom: 40px;
  } */
}

@media screen and (max-width:768px) {
  .cust-card {
    margin-top: 20px;
  }
}

/* Banner Section end */

/* how to buy section start */
.buy-section {
  padding: 100px 0;
}

.section-title {
  font-size: 2.5rem;
}

.section-title span {
  color: var(--tg-primary-color);
}

.buy-card {

  -webkit-transition: 250ms all linear 0ms;
  -khtml-transition: 250ms all linear 0ms;
  -moz-transition: 250ms all linear 0ms;
  -ms-transition: 250ms all linear 0ms;
  -o-transition: 250ms all linear 0ms;
  transition: 250ms all linear 0ms;
  position: relative;
  padding: 20px;
  margin-top: 20px;
  transition: 0.3s all ease-in-out;
  clip-path: polygon(0 28px, 28px 0, calc(100% - 28px) 0, 100% 28px, 100% calc(100% - 28px), calc(100% - 28px) 100%, 28px 100%, 0% calc(100% - 28px), 0% 28px);
  border: 2.10333px solid var(--tg-primary-color);
  min-height: 330px;
}

.boder {
  position: absolute;
}

.boder.bd1 {
  top: 0;
  left: -1px;
}

.boder.bd2 {
  top: 0;
  right: -1px;
  transform: rotate(180deg) rotatex(180deg);
}

.boder.bd3 {
  bottom: 0;
  right: -1px;
  transform: rotate(180deg) rotatex(0deg);
}

.boder.bd4 {
  bottom: 0;
  left: -1px;
  transform: rotate(0deg) rotatex(180deg);
}

.boder i {
  font-size: 53px;
  color: var(--tg-primary-color);
  -webkit-transition: 250ms all linear 0ms;
  -khtml-transition: 250ms all linear 0ms;
  -moz-transition: 250ms all linear 0ms;
  -ms-transition: 250ms all linear 0ms;
  -o-transition: 250ms all linear 0ms;
  transition: 250ms all linear 0ms;
}

.step-title {
  font-weight: 700;
  font-size: 30px;
  color: var(--tg-primary-color);
  margin: 15px 0;
}

/* how to buy section end */


/* token details start */
.token-section {
  padding: 100px 0;
}

.token-card {
  border: 1px solid var(--tg-primary-color);
  border-radius: 15px;
  background: #1E1B467A;
  padding: 15px;
}

.token-card-inner {
  border: 1px solid var(--tg-primary-color);
  border-radius: 15px;
  background: rgba(255, 255, 255, .1);
  padding: 15px;
}

.item--title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 7px;
}

.item-desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
}

/* .img-animate {
  animation: top_bottom 12s ease-out infinite;
} */

@keyframes top_bottom {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateY(-20px)
  }

  50% {
    transform: translateY(0)
  }

  75% {
    transform: translateY(20px)
  }

  100% {
    transform: translateY(0)
  }
}

.chart-wrap {
  display: flex;
  justify-content: center;
}

.chart-wrap .chart {
  width: 420px;
  height: 420px;
}

@media (max-width: 768px) {
  .chart-wrap .chart {
    width: 320px;
    height: 320px;
  }
}

.cust-progress {
  height: 20px;
  width: 80%;
  background-color: #d3d3d3;
  border-radius: 45px;
  overflow: hidden;
}

.token-title {
  font-size: 1rem;
  color: var(--primary-color);
}

.progress-fill {
  height: 100%;
  width: 0;
  background-color: #22cfcf;
  border-radius: 10px;
  transition: width 2s ease-in-out;
}

.share-footer__column .title_beige {
  font-size: 28px;
  line-height: 125%;
  color: #fff;
  font-weight: 600;
}

.content-text {
  font-weight: 300;
  margin: 30px 0 32px;

}

.content-text_blue {
  font-weight: 500;
  font-size: 20px;
  color: var(--tg-primary-color);
}

/* token details end */


/* Roadmap section start */
.roadmap-section {
  padding: 25px 0;
}

.roadmap-title {
  text-align: center;
  padding: 10px 0;
}

.success {
  background: #78AD42 !important;
}

.timeline-container {
  max-width: 1000px;
  margin: 0 auto;
}

#timeline .timeline-item:after,
#timeline .timeline-item:before {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: var(--tg-primary-color);
  left: 50%;
  top: 0;
  position: absolute;
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}

#timeline .timeline-item {
  margin-bottom: 50px;
  position: relative;
}

#timeline .timeline-item .timeline-icon {
  background: var(--tg-primary-color);
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  margin-left: -30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  padding: 5px;
}

#timeline .timeline-item .timeline-icon svg,
#timeline .timeline-item .timeline-icon img {
  position: relative;
  top: 10px;
  left: 0px;
  font-size: 24px;
  color: #fff;
}

#timeline .timeline-item .timeline-content {
  width: 45%;
  background: #fff;
  padding: 20px;
  -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#timeline .timeline-item .timeline-content h2 {
  padding: 15px;
  background: var(--tg-primary-color);
  color: #fff;
  margin: -20px -20px 0 -20px;
  font-weight: 500;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  font-size: 22px;
}

.timeline-content ul {
  padding-left: 1rem;
  margin: 0;
}

.timeline-content ul li {
  color: #121923;
}

#timeline .timeline-item .timeline-content p {
  padding-top: 10px;
  padding-bottom: 10px;
}

#timeline .timeline-item .timeline-content:before {
  content: '';
  position: absolute;
  left: 45%;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--tg-primary-color);
}

#timeline .timeline-item .timeline-content.right {
  float: right;
}

#timeline .timeline-item .timeline-content.right:before {
  content: '';
  right: 45%;
  left: inherit;
  border-left: 0;
  border-right: 7px solid var(--tg-primary-color);
}


@media screen and (max-width: 768px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
  }

  #timeline:before {
    left: 0;
  }

  #timeline .timeline-item .timeline-content {
    width: 90%;
    float: right;
  }

  #timeline .timeline-item .timeline-content:before,
  #timeline .timeline-item .timeline-content.right:before {
    left: 10%;
    margin-left: -6px;
    border-left: 0;
    border-right: 7px solid var(--tg-primary-color);
  }

  #timeline .timeline-item .timeline-icon {
    left: 0;
  }
}


/* icon animation */
.timeline-icon.is-hidden {
  visibility: hidden;
}

.timeline-icon.animate-it {
  visibility: visible;
  -webkit-animation: bounce-1 0.6s;
  -moz-animation: bounce-1 0.6s;
  animation: bounce-1 0.6s;
}

/* content block animation */
.timeline-content.is-hidden {
  visibility: hidden;
}

.timeline-content.animate-it {
  visibility: visible;
  -webkit-animation: bounce-2 0.6s;
  -moz-animation: bounce-2 0.6s;
  animation: bounce-2 0.6s;
}

@media only screen and (min-width: 769px) {

  /* Inverse bounce effect on even content blocks */
  .timeline-content.right.animate-it {
    -webkit-animation: bounce-2-inverse 0.6s;
    -moz-animation: bounce-2-inverse 0.6s;
    animation: bounce-2-inverse 0.6s;
  }
}

@media only screen and (max-width: 768px) {

  /* Inverse bounce effect on all content blocks */
  .timeline-content.animate-it,
  .timeline-content.right.animate-it {
    -webkit-animation: bounce-2-inverse 0.6s;
    -moz-animation: bounce-2-inverse 0.6s;
    animation: bounce-2-inverse 0.6s;
  }
}

/* ALL animation types called */

@-webkit-keyframes bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

/* Roadmap section end */

/* presale price section start */
.presale-section {
  padding: 50px 0;
}

.table>:not(caption)>*>* {
  background-color: transparent;
}

.table-dark {
  border-color: var(--tg-primary-color);
}

/* presale price section end */

/* faq section start */
.faq-accordion .card {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  margin-bottom: 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.faq-accordion .card .card-header {
  background-color: rgba(0, 0, 0, 0);
  border: 0 none;
  padding: 0;
  z-index: 2;
}

.faq-accordion .card .card-header a {
  background-color: var(--tg-primary-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
  display: block;
  padding: 15px 40px 15px 15px;
  position: relative;
  cursor: pointer;
}

.card-header a::after {
  color: #ffffff;
  content: "\f106";
  font-family: "Font Awesome 6 Free";
  font-size: 18px;
  font-weight: 900;
  position: absolute;
  right: 15px;
  top: 14px;
}

.card-header a.collapsed::after {
  content: "\f107";
  color: #fff;
}

.faq-accordion .card .card-header a span::before {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: var(--tg-primary-color) transparent transparent transparent;
}

.faq-accordion .card .card-header a span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent var(--tg-primary-color) transparent transparent;
}

.faq-accordion .card .card-header ins {
  background-color: var(--tg-primary-color);
  top: 100%;
  height: 10px;
  position: absolute;
  left: 10px;
  right: 10px;
}

.faq-accordion .card .card-body {
  background-color: var(--tg-primary-color);
  color: var(--primary-color);
  padding: 10px 20px;
  margin: 0 10px;
}

.icon {
  color: var(--primary-color);
}

.fa-14 {
  font-size: 14px;
}

/* faq section end */

.banner-img {
  height: 250px;
}

.input-group-text {
  background-color: #3c3d4c;
  border: none;
}

.ref-section .input-group::placeholder {
  text: #fff;
}


.h-400 {
  height: 350px !important;
}

#whitepaper img {
  height: 350px;
}


.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #10131de6;
  /* Slightly transparent background */
  z-index: 10;
  border-radius: 43px;
}


.card.cust-card {
  position: relative;
}

.card.cust-card.blur {
  filter: blur(5px);
}


.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #fff;
  background-color: #3c3d4c;
  cursor: not-allowed;
}


.overlay-content {
  background: #000000;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.overlay-content h2 {
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.overlay-content p {
  font-size: 16px;
  color: #fff;
}

.fa-12 {
  font-size: 12px !important;
}

.modal-content {
  background: #111923;
}

.modal-header {
  background: #37b9fc;
}


@media only screen and (max-width: 600px) {
  .h-400 {
    height: 250px !important;
  }

  tr {
    white-space: nowrap;
  }

  .amount-raised {
    font-size: 12px;
  }

  .claim-btn {
    font-size: 12px;
  }

  .buy-section,
  .token-section {
    padding: 60px 0;
  }
}



.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}
.countdown {
  text-align: center;
}
.countdown .time-unit {
  margin: 0 10px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #fff;
}
.countdown .time-unit span {
  display: block;
  font-size: 2rem;
  font-weight: bold;
}
.countdown .time-unit small {
  display: block;
  font-size: 1rem;
  font-weight: normal;
}